<template>
  <app-card class="status-card card-success">
    <div class="status-content">
      <div class="status-icon">
        <i class="fas fa-check"></i>
      </div>

      <div>
        <h2 class="title">Ön Başvuru Kaydedildi</h2>
        <p>
          Üyelik başvurunuz kayda alınmış ve başvurunuzda belirttiğiniz
          <strong>{{ applicationData.email }}</strong> e-posta adresinize
          başvurunuzun tamamlanması için yapılması gereken işlemler ile ilgili
          yönergeler gönderilmiştir. Başvurunuza dilerseniz şimdi dilerseniz de
          daha sonra aşağıdaki başvuru kodunuz kullanarak devam edebilirsiniz.
        </p>

        <div class="application-code">
          <label>BAŞVURU KODU</label>
          <div class="code">{{ applicationData.approvalRequest?.code }}</div>
        </div>

        <button class="btn btn-primary" @click="onSubmit">
          Dosyaları Yükle
        </button>

        <div style="padding-top:70px;">
          <h4>Yönergeler size ulaşmadı mı?</h4>
        </div>
        <p>
          Eğer yönergeler e-posta adresinize ulaşmadıysa
          <strong>"Yönergeleri Tekrar Gönder"</strong> butonuna basarak
          yönergelerin gönderilmesini sağlayabilirsiniz.
        </p>
        <div class="pt-5">
          <button class="btn btn-outline-secondary">
            Yönergeleri Tekrar Gönder
          </button>
        </div>
      </div>
    </div>
  </app-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    onSubmit() {
      this.showPageLoader();

      const payload = {
        email: this.applicationData.email,
        code: this.applicationData.approvalRequest.code
      };

      this.loadApplicationData(payload)
        .then((response) => {
          console.log(response);
          this.goTo("/upload/" + this.applicationData.stateId);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.hidePageLoader();
        });
    },
    ...mapActions(["loadApplicationData"])
  },
  computed: {},
  beforeMount() {
    this.updateApplicationData({
      fromReview: true
    });
  }
};
</script>
<style scoped lang="scss">
@import "../scss/_variables";

.application-code {
  background: #f7f7f7;
  color: #000;
  padding: 30px;
  margin: 40px 0;
  .code {
    font-size: 36px;
    line-height: 36px;
    font-weight: $font-weight-extra-bold;
  }

  label {
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
  }
}

.card-primary {
  color: rgba(255, 255, 255, 0.8);

  strong,
  b {
    color: #fff;
  }

  .title {
    color: #fff;
  }
}

.status-content {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  //color: #fff;

  .title {
    //color: #fff;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: $primary;
  }

  .status-icon {
    i {
      font-size: 3rem;
      color: $primary;
      margin-right: 40px;
    }
  }
}
</style>
